import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Özgürlüğe Yolculuk
			</title>
			<meta name={"description"} content={"Amacımız size yalnızca en kaliteli bisikletleri sunmak değil, aynı zamanda her yolculuğun unutulmaz olmasını sağlayacak destek ve kaynakları da sunmaktır."} />
			<meta property={"og:title"} content={"Özgürlüğe Yolculuk"} />
			<meta property={"og:description"} content={"Amacımız size yalnızca en kaliteli bisikletleri sunmak değil, aynı zamanda her yolculuğun unutulmaz olmasını sağlayacak destek ve kaynakları da sunmaktır."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z"} />
		</Helmet>
		<Components.Contact />
		<Components.Nav />
		<Components.Hero background="linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-2.jpg?v=2024-06-07T06:19:36.876Z) 50% 50% /cover repeat scroll padding-box">
			<Override slot="button" href="/contacts">
				Контакти
			</Override>
			<Override slot="text1">
				Kapsamlı Hizmetlerimiz
			</Override>
			<Override slot="text">
				Послуги
			</Override>
			<Override slot="text2">
				SpeedThrill Motosiklet Kiralama olarak, tüm motosiklet kiralama ihtiyaçlarınızı karşılamak üzere tasarlanmış geniş bir hizmet yelpazesi aracılığıyla üstün bir sürüş deneyimi sunmaya kendimizi adadık. Amacımız size yalnızca en kaliteli bisikletleri sunmak değil, aynı zamanda her yolculuğun unutulmaz olmasını sağlayacak destek ve kaynakları da sunmaktır. Kapsamlı hizmetlerimizi keşfedin ve SpeedThrill'in motosiklet maceralarınız için neden en iyi seçim olduğunu keşfedin.
			</Override>
			<Override slot="text3" />
			<Override slot="box1" display="none" />
		</Components.Hero>
		<Section padding="90px 0 100px 0" background="linear-gradient(180deg,--color-dark 0%,rgba(1, 1, 1, 0.6) 72.9%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/2-1.jpg?v=2024-06-07T06:19:36.882Z) 0% 0% /cover no-repeat scroll padding-box" quarkly-title="Price-18">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 2rem 0px" text-align="center" font="--headline2" color="--light">
				Kapsamlı Hizmetlerimiz
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/2-4.jpg?v=2024-06-07T06:19:36.874Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/6661a2aefdcae00021e2f754/images/2-4.jpg?v=2024-06-07T06%3A19%3A36.874Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6661a2aefdcae00021e2f754/images/2-4.jpg?v=2024-06-07T06%3A19%3A36.874Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6661a2aefdcae00021e2f754/images/2-4.jpg?v=2024-06-07T06%3A19%3A36.874Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6661a2aefdcae00021e2f754/images/2-4.jpg?v=2024-06-07T06%3A19%3A36.874Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6661a2aefdcae00021e2f754/images/2-4.jpg?v=2024-06-07T06%3A19%3A36.874Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6661a2aefdcae00021e2f754/images/2-4.jpg?v=2024-06-07T06%3A19%3A36.874Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6661a2aefdcae00021e2f754/images/2-4.jpg?v=2024-06-07T06%3A19%3A36.874Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Çeşitli motosiklet filomuz, kiralama hizmetlerimizin temel taşıdır.{" "}
								<br />
								Farklı sürüş tarzlarına ve tercihlerine hitap edecek çeşitli modeller sunarak yolculuğunuz için mükemmel bisikleti bulmanızı sağlıyoruz.{"\n"}
								<br />
								{"\n"}Spor Bisikletleri: Adrenalin arayanlar ve yarış tutkunları için mükemmel olan yüksek performanslı spor bisikletlerimizle hız ve çevikliğin heyecanını yaşayın.{"\n"}
								<br />
								Son Teknoloji: Üstün performans için motosiklet teknolojisindeki en son gelişmelerle donatılmıştır.{"\n"}
								<br />
								Şık Tasarım: Aerodinamik ve şık, maksimum hız ve kontrol için tasarlandı.{"\n"}
								<br />
								Güçlü Motorlar: Etkileyici hızlanma ve en yüksek hızlar sağlayan sağlam motorlar.
								<br />
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/2-3.jpg?v=2024-06-07T06:19:36.876Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/6661a2aefdcae00021e2f754/images/2-3.jpg?v=2024-06-07T06%3A19%3A36.876Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6661a2aefdcae00021e2f754/images/2-3.jpg?v=2024-06-07T06%3A19%3A36.876Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6661a2aefdcae00021e2f754/images/2-3.jpg?v=2024-06-07T06%3A19%3A36.876Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6661a2aefdcae00021e2f754/images/2-3.jpg?v=2024-06-07T06%3A19%3A36.876Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6661a2aefdcae00021e2f754/images/2-3.jpg?v=2024-06-07T06%3A19%3A36.876Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6661a2aefdcae00021e2f754/images/2-3.jpg?v=2024-06-07T06%3A19%3A36.876Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6661a2aefdcae00021e2f754/images/2-3.jpg?v=2024-06-07T06%3A19%3A36.876Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								<br />
								Kruvazör: Uzun mesafeli yolculuklar ve keyifli yolculuklar için ideal olan kruvazör seçeneklerimizle konforlu ve rahat bir yolculuğun keyfini çıkarın.{"\n"}
								<br />
								Konforlu Oturma: Yolda uzun saatler boyunca rahatsızlık duymadan kullanabilmeniz için ergonomik olarak tasarlanmıştır.{"\n"}
								<br />
								Sorunsuz Kullanım: Kolay manevra yaparak sorunsuz ve keyifli bir sürüş sağlar.{"\n"}
								<br />
								Klasik Estetik: Zarafet ve incelik yayan zamansız tasarımlar.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-4.jpg?v=2024-06-07T06:19:36.876Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-4.jpg?v=2024-06-07T06%3A19%3A36.876Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-4.jpg?v=2024-06-07T06%3A19%3A36.876Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-4.jpg?v=2024-06-07T06%3A19%3A36.876Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-4.jpg?v=2024-06-07T06%3A19%3A36.876Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-4.jpg?v=2024-06-07T06%3A19%3A36.876Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-4.jpg?v=2024-06-07T06%3A19%3A36.876Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-4.jpg?v=2024-06-07T06%3A19%3A36.876Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Ekipman Kiralama: Kasklar, ceketler, eldivenler ve daha fazlasını içeren yüksek kaliteli ekipman kiralamalarımızla yolculuğunuzu tamamlayın.
								<br />
								{"\n"}Güvenlik Baretleri: Maksimum koruma için DOT onaylı kasklar.
								<br />
								{"\n"}Koruyucu Ceketler: Daha fazla güvenlik için dolgulu, dayanıklı ceketler.{"\n"}
								<br />
								Binicilik Eldivenleri: Daha iyi kavrama ve kontrol için konforlu ve koruyucu eldivenler.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Nav />
		<Components.Contact />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66329762fdcae00021e1d75a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});